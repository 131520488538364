import React from 'react'
import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Posts from '../components/Posts/Posts'

import '../scss/pages/default.scss';

export default function Default({pageContext}) {
    const heading = pageContext.heading
    const content = pageContext.content
    const seo = pageContext.seo

    return (
        <Layout>
            <Seo title={seo.title} description={seo.metaDesc} keywords={seo.focuskw} pathname={seo.opengraphUrl}/>
            <div className="page-top-section">
                <div className="container custom-container">
                    <div className="row">
                        <div className="col-md-12">
                        <p className="small-header text-center mb-0 text-white">{pageContext.tile}</p>
                        <h1 className="header-h1 text-center mt-15 mb-30 text-white">{heading}</h1>
                        <p className="paragraph text-center text-white font-light">{pageContext.header_content}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 page-content paragraph" dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                </div>
            </div>
            <Posts />
        </Layout>
    )
}
